import React from "react"
import styled from "styled-components"

import Image from "../ui/Image"
import { Title } from "../typography"
import { SecondaryButton } from "../ui/Buttons"
import { Link } from "react-router-dom"
import { useCart } from "../../hooks"

export const LineItem = ({ lineItem }) => {
  const { removeFromCart } = useCart()
  const variantName =
    lineItem.variant.title === "Default Title" ? "" : lineItem.variant.title
  return (
    <Row>
      <InformationContainer>
        <ImageContainer>
          {lineItem.variant.image ? (
            <Link to={`/product/${lineItem.variant.product.id}`}>
              <Image
                rounded
                fullWidth
                imageUrl={lineItem.variant.image.src}
                alt={`${lineItem.variant.title} product shot`}
              />
            </Link>
          ) : null}
        </ImageContainer>
      </InformationContainer>
      <InformationContainer>
        <Title>
          {lineItem.title} <br />
          {variantName && (
            <>
              {variantName} <br />
            </>
          )}
        </Title>
        <Title>Cantidad: {lineItem.quantity}</Title>
      </InformationContainer>
      <InformationContainer>
        <Title marginLess>
          ${(lineItem.quantity * lineItem.variant.price.amount).toFixed(2)} MXN
        </Title>
      </InformationContainer>
      <RemoveButton onClick={() => removeFromCart(lineItem.id)}>×</RemoveButton>
      <RemoveButtonSmall>
        <SecondaryButton
          text={"Eliminar"}
          fullWidth={true}
          onClick={() => removeFromCart(lineItem.id)}
        />
      </RemoveButtonSmall>
    </Row>
  )
}
const Row = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    border: 1px #eee solid;
    border-radius: 5px;
    padding: 15px;
  }
`
const ImageContainer = styled.div`
  height: auto;
  width: 200px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 15px;
    border-radius: 5px;
  }
`
const InformationContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: middle;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
  }
`

const LineButton = styled.button`
  background-color: transparent;
  border: hidden;
`

const RemoveButton = styled(LineButton)`
  color: red;

  @media (max-width: 768px) {
    display: none;
  }
`

const RemoveButtonSmall = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    padding: 15px;
  }
`

export default LineItem
