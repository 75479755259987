import React from 'react';
import Layout from '../components/layout';
import Row from 'react-bootstrap/Row';

import Product from '../components/shop/Product';

import useProducts from '../hooks/useProducts';

export const Shop = () => {
    const products = useProducts()
    
    const renderProducts = () => {
        return products.map((product) => (
            <Product key={product.id} product={product} />
        ))
    }

    return (
        <Layout>
            <Row className="my-2">
                {renderProducts()}
            </Row>
        </Layout>
    );
};

export default Shop;