import React from "react"
import { default as ReactButton } from "react-bootstrap/Button"
import styled from "styled-components"
import { white, black, darkBlack } from "../../constants/colors"

export const PrimaryButton = ({
  text,
  onClick,
  size,
  fullWidth = false,
  disabled = false,
}) => {
  return (
    <Primary
      size={size}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}>
      {text}
    </Primary>
  )
}

export const SecondaryButton = ({
  text,
  onClick,
  size,
  fullWidth = false,
  disabled = false,
}) => {
  return (
    <Secondary
      size={size}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}>
      {text}
    </Secondary>
  )
}

const Base = styled(ReactButton)`
  font-family: "Kalinga";
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  border-radius: 5px;
`

const Primary = styled(Base)`
  background-color: ${black};
  color: ${white};
  border: hidden;
  &:hover {
    background-color: ${darkBlack} !important;
    color: ${white};
  }

  &:active,
  &:focus {
    background-color: ${darkBlack} !important;
    color: ${white};
  }
  &:disabled {
    background-color: ${darkBlack} !important;
    color: ${white};
  }
`

const Secondary = styled(Base)`
  background-color: ${white};
  color: ${black};
  border-color: ${darkBlack} !important;

  &:hover {
    background-color: ${white} !important;
    color: ${darkBlack} !important;
    border-color: ${darkBlack} !important;
  }

  &:active,
  &:focus {
    background-color: ${white} !important;
    color: ${darkBlack} !important;
    border-color: ${darkBlack} !important;
  }
`
