import styled from "styled-components"
import { grey, black } from "../../constants/colors"
import { Link } from "react-router-dom"

export const Paragraph = styled.p`
  font-family: "Kalinga";
  color: ${grey};
`

export const Title = styled.p`
  font-size: 15px;
  font-family: "Kalinga";
  color: ${black};
  margin-bottom: ${(props) => (props.marginLess ? "0" : "10px")};
`
export const Header = styled.h2`
  font-family: "Kalinga";
  color: ${grey};
`

export const NavLink = styled(Link)`
  font-family: "Kalinga";
  color: ${grey};
`

export const Button = styled.button`
  font-family: "Kalinga";
`
