import Layout from '../components/layout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Header, Paragraph } from './../components/typography';
import { Image } from 'react-bootstrap';
export const Contact = () => {
    return (
        <Layout>
            <Row className="py-5">
                <Col sm={6}>
                    <Row>
                        <Col sm={12}>
                            <Header>Contacto</Header>
                        </Col>
                        <Col sm={12} className="my-4">
                            <Paragraph>contacto@thenorm.mx</Paragraph>
                            <Paragraph>+52 33 31 69 68 01</Paragraph>
                            <Paragraph>+52 1 33 31 27 59 10</Paragraph>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Image src="images/contacto.jpg" fluid rounded/>
                </Col>
            </Row>
        </Layout>
    );
};

export default Contact;