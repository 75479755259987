export const routes = {
    home: '/',
    thenorm: '/thenorm',
    now: '/now',
    shop: '/shop',
    cart: '/cart',
    stores: '/stores',
    contact: '/contact',
};

export default routes;