import Client from 'shopify-buy';

const client = Client.buildClient({
    domain: 'the-normmx.myshopify.com',
    storefrontAccessToken: '95201de6fcd075665acb70b175a950a5'
});

const initialState = {
    products: [],
    checkout: undefined,
    cart: [],
    client,
    language: 'es',
    isNativeApp: window.isNativeApp,
}

export default initialState