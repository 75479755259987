import React, { useState } from "react"
import styled from "styled-components"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"

import Image from "../ui/Image"
import { PrimaryButton, SecondaryButton } from "../ui/Buttons"
import { Title } from "../typography"
import { getVariant } from "../../helpers"
import { useCart } from "../../hooks"
import { Link, useHistory } from "react-router-dom"

export const Product = ({ product }) => {
  const { isAddingProduct, addToCart } = useCart()
  const [variantSelected, setVariantSelected] = useState(
    product.variants.length ? product.variants[0] : null
  )
  const history = useHistory()

  const renderVariantSelector = (variants) => {
    return (
      <VariantContainer>
        <Form.Control
          as="select"
          custom
          onChange={(event) =>
            setVariantSelected(getVariant(event.target.value, variants))
          }>
          {variants.map((variant) => (
            <option key={variant.id} value={variant.id}>
              {variant.name} {variant?.available ? null : "(Agotado)"}
            </option>
          ))}
        </Form.Control>
        {variantSelected && (
          <VariantPrice>{variantSelected.priceTxt}</VariantPrice>
        )}
      </VariantContainer>
    )
  }

  const onClickProduct = (productId) => {
    history.push(`/product?id=${productId}`)
  }

  return (
    <Col
      sm={12}
      md={3}
      lg={4}
      key={product.id}
      style={{ paddingLeft: "3px", paddingRight: "3px" }}>
      <ProductContainer>
        {variantSelected && (
          <Link to={`/product?id=${product.id}`}>
            <Image
              imageUrl={variantSelected.image}
              alt={product.name}
              fullWidth
              rounded
            />
          </Link>
        )}
        <ProductName>{product.name}</ProductName>
        {product.variants && renderVariantSelector(product.variants)}
        <PrimaryButton
          size="md"
          fullWidth={true}
          disabled={!variantSelected?.available}
          text={
            isAddingProduct ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              "Anadir al Carrito"
            )
          }
          onClick={() => addToCart(variantSelected)}
        />
        <div className="pb-2"></div>
        <SecondaryButton
          size="md"
          fullWidth={true}
          text="Ver Detalle"
          onClick={() => onClickProduct(product.id)}
        />
      </ProductContainer>
    </Col>
  )
}

const ProductName = styled(Title)`
  padding: 15px 0px;
  margin-bottom: 0px;
  text-align: center;
`

const ProductContainer = styled.div`
  padding: 5px;
  margin: 5px auto;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const VariantContainer = styled.div`
  margin-bottom: 15px;
  width: 80%;
  justify-content: center;
`

const VariantPrice = styled(Title)`
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
`

export default Product
