import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { Link } from "react-router-dom"
import { routes } from "../../constants/routes"
import { NavLink } from "../typography/index"

import Minicart from "../cart/MiniCart"
import useCheckout from "./../../hooks/useCheckout"
import Container from "react-bootstrap/esm/Container"

export default function CustomNavbar() {
  const checkout = useCheckout()

  return (
    <Navbar bg="white" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand>
          <Link to={routes.home}>
            <img width="80" height="80" src="/images/logo.png" alt="logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navbar" />
        <Navbar.Collapse id="main-navbar" className="justify-content-end">
          <Nav className="flex-end">
            <NavLink to={routes.thenorm} className="nav-link" role="button">
              THE NORM
            </NavLink>
            <NavLink to={routes.shop} className="nav-link" role="button">
              COMPRAR
            </NavLink>
            <NavLink to={routes.stores} className="nav-link" role="button">
              TIENDAS
            </NavLink>
            <NavLink to={routes.contact} className="nav-link" role="button">
              CONTACTO
            </NavLink>
            <NavLink to={routes.cart} className="nav-link" role="button">
              <Minicart
                productCount={checkout ? checkout.lineItems.length : 0}
              />
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
