import { useEffect, useState } from "react"
import normalizeProduct from "../helpers/normalizeProduct"
import useClient from "./useClient"

export const useProduct = (productId) => {
  const client = useClient()
  const [product, setProduct] = useState()
  const [isLoadingProduct, setIsLoadingProduct] = useState(false)
  useEffect(() => {
    const fetchProduct = async () => {
      setIsLoadingProduct(true)
      const fetchedProduct = await client.product.fetch(productId)
      const normalisedProduct = normalizeProduct(fetchedProduct)
      setProduct(normalisedProduct)
      setIsLoadingProduct(false)
    }

    if (!product) fetchProduct()
  }, [productId, product, client])

  return { product, isLoadingProduct }
}

export default useProduct
