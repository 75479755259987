import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Carousel from "react-bootstrap/Carousel"
import Image from "../components/ui/Image"
import Layout from "../components/layout"
import Form from "react-bootstrap/Form"
import Spinner from "react-bootstrap/Spinner"
import { useState } from "react"
import { Loading } from "./loading"
import { Header, Paragraph, Title } from "../components/typography"
import { getVariant } from "../helpers"
import { PrimaryButton } from "../components/ui/Buttons"
import { useCart, useQuery } from "../hooks"
import useProduct from "../hooks/useProduct"
import { useEffect } from "react"
import styled from "styled-components"
import Breadcrumb from "react-bootstrap/Breadcrumb"

export const ProductDetail = () => {
  const query = useQuery()
  const productId = query.get("id")
  const { product, isLoadingProduct } = useProduct(productId)
  const { isAddingProduct, addToCart } = useCart()
  const [variantSelected, setVariantSelected] = useState()
  const [carouselIndex, setCarouselIndex] = useState(0)

  useEffect(() => {
    if (product) setVariantSelected(product.variants[0])
  }, [productId, product])

  useEffect(() => {
    setCarouselIndex(0)
  }, [variantSelected])

  const renderImages = (images) => {
    if (variantSelected) {
      images = [variantSelected.image, ...images]
    }
    return images.map((image, index) => {
      return (
        <Carousel.Item key={`image-${image}-${index}`}>
          <Image imageUrl={image} fullWidth={true} rounded alt="Product" />
        </Carousel.Item>
      )
    })
  }

  const renderVariantSelector = (variants) => {
    return (
      <div>
        {variantSelected && (
          <Title className="mt-2">
            <b>Precio</b>: {variantSelected.priceTxt}
          </Title>
        )}
        <Form.Control
          as="select"
          className="mb-3"
          custom
          onChange={(event) =>
            setVariantSelected(getVariant(event.target.value, variants))
          }>
          {variants.map((variant) => (
            <option key={variant.id} value={variant.id}>
              {variant.name} {variant.available === false ? "(Agotado)" : ""}
            </option>
          ))}
        </Form.Control>
        {variants.map((variant, index) => {
          return (
            <SmallImageContainer className="mb-2">
              <Image
                thumbnail
                key={`${variant.id}-image-sm-${index}`}
                imageUrl={variant.image}
                alt={variant.name}
              />
            </SmallImageContainer>
          )
        })}
      </div>
    )
  }

  if (isLoadingProduct) return <Loading />
  if (!product) return <></>

  const { name, description, images } = product

  return (
    <Layout>
      <Row className="py-2">
        <Col className="p-0">
          <Breadcrumb listProps={{ style: { background: "transparent" } }}>
            <Breadcrumb.Item href="/">PRODUCTOS</Breadcrumb.Item>
            <Breadcrumb.Item active>{name}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col sm={12} className="py-2">
          <Header>{name}</Header>
          <Paragraph>{description}</Paragraph>
        </Col>
        <Col md={5} sm={12}>
          <Carousel
            interval={null}
            touch={true}
            activeIndex={carouselIndex}
            onSelect={(eventKey) => setCarouselIndex(eventKey)}>
            {renderImages(images)}
          </Carousel>
        </Col>
        <Col md={7} sm={12}>
          {product.variants && renderVariantSelector(product.variants)}
          <PrimaryButton
            fullWidth={true}
            disabled={variantSelected.available === false}
            size="lg"
            text={
              isAddingProduct ? (
                <Spinner animation="border" variant="light" size="sm" />
              ) : (
                "Anadir al Carrito"
              )
            }
            onClick={() => addToCart(variantSelected)}
          />
        </Col>
      </Row>
    </Layout>
  )
}

const SmallImageContainer = styled.div`
  height: 50px;
  width: 50px;
  display: inline-block;
  padding-right: 5px;
`
