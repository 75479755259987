import Layout from "../components/layout"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Spinner from 'react-bootstrap/Spinner'
import { Header } from "../components/typography";

export const Loading = () => {
    return (
        <Layout>
            <Row className='py-5'>
                <Col sm={12}>
                    <Header className="text-center">
                    <Spinner animation="grow" /> Cargando
                    </Header>
                </Col>
            </Row>
        </Layout>
    )
}