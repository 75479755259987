import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import LineItem from '../components/cart/LineItem'
import { Header, Title } from '../components/typography'
import { PrimaryButton } from '../components/ui/Buttons'
import { useCheckout } from '../hooks';

export const Cart = () => {
    const checkout = useCheckout()
    
    const openCheckout = () => {
        window.open(checkout?.webUrl);
    }

    const renderLineItems = (lineItems) => {
        return lineItems.map((variant) => 
            <LineItem
                key={variant.id}
                lineItem={variant}
            />
        )
    }

    return (
        <Layout>
            <Row className="py-5">
                <Col sm={12}>
                    <CartContent>
                        <CartHeaderContainer>
                            <Header>Carrito</Header>
                        </CartHeaderContainer>    
                    </CartContent>
                </Col>
            </Row>
            <Row>
                {checkout && checkout.lineItems.length > 0 ?
                <>
                    <Col sm={8}>
                        {renderLineItems(checkout.lineItems)}
                    </Col>
                    <Col sm={4} xs={12}>
                        <FooterContainer>
                            <Title>
                                Total: ${checkout?.totalPrice.amount} MXN
                            </Title>
                            { checkout.lineItems.length > 0 &&
                                <PrimaryButton onClick={() => openCheckout()} text="Pagar" size="md" fullWidth={true}/>
                            }
                        </FooterContainer>
                    </Col>
                </> : <Col xs={12}><Title>Tu carrito esta vacío</Title></Col>  
                }
            </Row>
        </Layout>
    )
}

const CartContent = styled.div`
    display:flex;
    flex:1;
    flex-direction: column;
`;

const CartHeaderContainer = styled.header`
    width: 100%;
`;

const FooterContainer = styled.footer`
 padding: 15px;
 border-left: 2px solid #eee;
 @media (max-width: 768px){
    border: hidden;
 }
`;

export default Cart