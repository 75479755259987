import { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from '../components/layout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import { Paragraph, Header } from '../components/typography';
import api from '../constants/apiRoutes';
import decoder from '../helpers/decoder';

export const Stores = () => {
    const [stores, setStores] = useState([]);

    useEffect(() => {
        const getStores = async () => {
            const response = await axios.get(api.stores);
            if (response.data){
                setStores(response.data);
            }
        };

        getStores();
    }, []);

    const renderStores = () => {
        return stores.map((store, index) => (
            <Col sm={4} key={index}>
                <StoreName>{store.name}</StoreName>
                <Paragraph dangerouslySetInnerHTML={{__html: decoder(store.address)}} />
            </Col>
        ));
    };

    return (
        <Layout>
            <Row className="my-5">
                <Col sm={12}>
                    <Header>Tiendas</Header>
                </Col>
            </Row>
            <Row className="my-5">
                {renderStores()}
            </Row>
        </Layout>
    );
};

const StoreName = styled(Header)`
    font-size: 22px;
`;

export default Stores;