import Navbar from "../navbar/CustomNavbar"
import Container from "react-bootstrap/Container"
import "bootstrap/dist/css/bootstrap.min.css"
import { useStore } from "../../store"
import { useEffect } from "react"
import { operationSetCheckout } from "../../duck/operations"
import { useCookies } from "react-cookie"
import { CHECKOUT } from "../../constants/cookieNames"

const Layout = ({ children }) => {
  const [state, dispatch] = useStore()
  const { isNativeApp, client, checkout } = state
  const [cookies, setCookie] = useCookies()

  useEffect(() => {
    const createCheckout = async () => {
      try {
        const newCheckout = await client.checkout.create()
        dispatch(operationSetCheckout(newCheckout))
        setCookie("checkout-id", newCheckout.id)
      } catch (error) {
        console.error(error)
      }
    }

    const fetchCheckout = async (checkoutId) => {
      try {
        const newCheckout = await client.checkout.fetch(checkoutId)
        dispatch(operationSetCheckout(newCheckout))
        setCookie("checkout-id", newCheckout.id)
      } catch (error) {
        console.error(error)
      }
    }

    if (!checkout) {
      const checkoutId = cookies[CHECKOUT]
      if (checkoutId) {
        //fetch checkout using
        fetchCheckout(checkoutId)
      } else {
        createCheckout()
      }
    }
  }, [checkout, client, dispatch, cookies, setCookie])

  return (
    <Container fluid>
      {!isNativeApp && <Navbar />}
      <Container>{children}</Container>
      <footer></footer>
    </Container>
  )
}

export default Layout
