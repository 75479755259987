import { useState } from "react";
import { operationSetCheckout } from "../duck/operations";
import { useStore } from "../store";
import useCheckout from "./useCheckout";

export const useCart = () => {
    const [state, dispatch] = useStore()
    const { cart, client} = state
    const checkout = useCheckout()
    const [isAddingProduct, setIsAddingProduct] = useState(false)
    const [isRemovingProduct, setIsRemovingProduct] = useState(false)

    const addToCart = (productToAdd) => {
        setIsAddingProduct(true)
        try {
            client.checkout.addLineItems(checkout?.id, {variantId: productToAdd.id, quantity: 1})
            .then((updatedCheckout) => {
                dispatch(operationSetCheckout(updatedCheckout))
                setIsAddingProduct(false)
            })
        } catch (error) {
            console.log(error)
            setIsAddingProduct(false)
        }
    }

    const removeFromCart = (productToRemove) => {
        setIsRemovingProduct(true)
        try {
            client.checkout.removeLineItems(checkout.id, [productToRemove])
            .then((updatedCheckout) => {
                dispatch(operationSetCheckout(updatedCheckout))
                setIsRemovingProduct(false)
            })
        } catch (error) {
            console.log(error)
            setIsRemovingProduct(false)
        }
    }
    return {cart, isAddingProduct, addToCart, isRemovingProduct, removeFromCart}
}

export default useCart