import './App.css';
import React from 'react'
import AppRouter from './router';
import { reducer, initialState } from './duck';
import { StoreProvider } from './store';
  
const App = () => {
  return (
    <StoreProvider initialState={initialState} reducer={reducer}>
      <AppRouter />
    </StoreProvider>
  )
}

export default App;
