import { useEffect } from "react"
import { operationSetProducts } from "../duck/operations"
import { normalizeProducts } from "../helpers"
import { useStore } from "../store"

export const useProducts = () => {
  const [state, dispatch] = useStore()
  const { client, products } = state

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const products = await client.product.fetchAll(100)
        dispatch(operationSetProducts(normalizeProducts(products)))
      } catch (error) {
        console.log(error)
      }
    }

    if (!products.length > 0) {
      fetchProducts()
    }
  }, [dispatch, client, products])

  return products
}

export default useProducts
