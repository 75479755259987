import Layout from '../components/layout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Header } from '../components/typography';

export const Now = () => {
    return (
        <Layout>
            <Row className="py-5">
                <Col sm={12}>
                    <Header> Right Now </Header>
                </Col>
            </Row>
        </Layout>
    );
};

export default Now;