import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../components/layout';
import Image from 'react-bootstrap/Image';
import { Paragraph } from '../components/typography';

export const TheNorm = () => {
    const text1 = "La búsqueda de una profunda sensaciónn de pertenencia, de la unión de uno con el otro y con el momento presente, así como de una nueva significación al espacio interior son el fundamento.";
    const text2 = "Todo lo que diseñamos es para ser utilizado, atesorado, inspirado en el origen de las culturas, de quienes somos. Fusión entre trabajo manual y diseño minimal, sofisticado, de belleza discreta que da prioridad a la silueta y a la pureza, cubriendo y enriqueciendo necesidades de la vida práctica. Nuestros productos son hechos a mano, con materiales naturales y en colaboración con artesanos cuyos procesos y técnicas ancestrales son parte de su vida cotidiana, de su presente y de sus raíces.";
    const text3 = "Nuestro objetivo es cultivar el origen de las cosas, volver a sentir el objeto en la conciencia. Creemos en unir culturas de lo artesanal y de lo estético.";
    const text4 = "Hay un significado social y espiritual en traer belleza practica a nuestros hogares, una invitación a vivir una vida con sentido. Cuando encontramos la posibilidad de simplicidad en nosotros mismos, en lo que tomamos en nuestras manos, tocamos la vida de quien lo hizo y el origen de la materia misma. Cuando usamos un objeto hecho a mano, existe una interacción entre el objeto y nosotros, nuestras emociones y nuestro estado mental. Esta es la apuesta filosófica fundamental de THE NORM.";

    return (
        <Layout>
            <Row className="my-5">
                <Col sm={9} className="d-flex flex-column align-self-center">
                    <Paragraph>{text1}</Paragraph>
                    <Paragraph>{text2}</Paragraph>
                    <Paragraph>{text3}</Paragraph>
                    <Paragraph>{text4}</Paragraph>
                </Col>
                <Col sm={3}>
                    <Image src="images/collage texto.png" fluid/>
                </Col>
            </Row>
        </Layout>
    );
};

export default TheNorm;