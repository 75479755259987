import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    SET_CART,
    SET_CHECKOUT,
    SET_PRODUCTS,
} from './actions'

const reducer = (state, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            return {
                ...state,
            }
        case REMOVE_FROM_CART:
            return {
                ...state,
            }
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.products
            }
        case SET_CART:
            return {
                ...state,
                cart: action.cart
            }
        case SET_CHECKOUT:
            return {
                ...state,
                checkout: action.checkout
            }
        default:
            return state

    }

}

export default reducer