import {
  Now,
  Contact,
  Shop,
  Stores,
  TheNorm,
  Cart,
  ProductDetail,
} from "../pages"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/now">
          <Now />
        </Route>
        <Route exact path="/shop">
          <Shop />
        </Route>
        <Route exact path="/product">
          <ProductDetail />
        </Route>
        <Route exact path="/cart">
          <Cart />
        </Route>
        <Route exact path="/stores">
          <Stores />
        </Route>
        <Route exact path="/thenorm">
          <TheNorm />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route path="/">
          <Shop />
        </Route>
      </Switch>
    </Router>
  )
}

export default AppRouter
