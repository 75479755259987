import React from 'react'
import styled from 'styled-components'

import { Title } from '../typography'


const MiniCart = ({productCount}) => {

    return (
        <MiniCartText>{`CARRITO (${productCount})`}</MiniCartText>
    )
}

const MiniCartText = styled(Title)`
    margin-bottom: 0;
`

export default MiniCart