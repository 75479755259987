import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    SET_PRODUCTS,
    SET_CHECKOUT,
    SET_CART
} from './actions'


export const operationAddToCart = (product) => {
    return {
        type: ADD_TO_CART,
        product
    }
}

export const operationRemoveFromCart = (product) => {
    return {
        type: REMOVE_FROM_CART,
        product
    }
}

export const operationSetProducts = (products) => ({
    type: SET_PRODUCTS,
    products,
})

export const operationSetCheckout = (checkout) => ({
    type: SET_CHECKOUT,
    checkout,
})

export const operationSetCart = (cart) => ({
    type: SET_CART,
    cart,
})